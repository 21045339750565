<template>
  <div class="game-container">
    <LearnReview
      :imgList="imgList"
      :wordsList="wordsList"
      :sentenceList="sentenceList"
      @deleteWords="handleDeleteWords"
    />
  </div>
</template>

<script>
import LearnReview from "@/components/Course/GamePage/LearnReviewGame";
export default {
  data() {
    return {
      imgList: [
        {
          id: 1,
          imgUrl: require("@/assets/img/18-Blue-review/lesson-30-hengpiewangou-yellow.svg"),
          show: true,
          type: 3,
        },
        {
          id: 2,
          imgUrl: require("@/assets/img/18-Blue-review/lesson-30-zai-white.svg"),
          show: true,
          type: 3,
        },
        {
          id: 3,
          imgUrl: require("@/assets/img/18-Blue-review/lesson-30-na-white.svg"),
          show: true,
          type: 3,
        },
      ],
      wordsList: [
        {
          id: 1,
          name: "zài",
          namePin: "在",
          show: true,
          type: 1,
        },
        {
          id: 2,
          name: "zhèr",
          namePin: "这儿",
          show: true,
          type: 1,
        },
        {
          id: 3,
          name: "nàr",
          namePin: "那儿",
          show: true,
          type: 1,
        },
        {
          id: 4,
          name: "nǎr",
          namePin: "哪儿",
          show: true,
          type: 1,
        },
      ],
      sentenceList: [
        {
          id: 1,
          name: "Gēge zài nǎr?",
          namePin: "哥哥在哪儿？",
          show: true,
          type: 2,
        },
      ],
    };
  },
  components: {
    LearnReview,
  },
  methods: {
    handleDeleteWords({ type, id }) {
      if (type === 1) {
        this.wordsList[id - 1].show = false;
      } else if (type === 2) {
        this.sentenceList[id - 1].show = false;
      } else {
        this.imgList[id - 1].show = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
