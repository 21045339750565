<template>
  <div class="game-container">
    <DragToDressGame
      :bgImgList="bgImgList"
      :bgImg="bgImg"
      :optionImgList="optionImgList"
      :lessonNO="30"
      :titleInfo="titleInfo"
      :hasSentence="false"
    />
  </div>
</template>

<script>
import DragToDressGame from "@/components/Course/GamePage/DragToDressGame";
export default {
  data() {
    return {
      titleInfo:{
        pinyin:"Zài...",
        hanzi:"在……"
      },
      bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-30/background.svg"),
      bgImgList: [
        {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-30/cup-done.svg"),
          id: 1,
          index:1,
        },
                {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-30/muffin-done.svg"),
          id: 2,
          index:2,
        },
                {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-30/fruit-done.svg"),
          id: 3,
          index:3,
        },
                {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-30/ball-done.svg"),
          id: 4,
          index:4,
        },
                {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-30/bear-done.svg"),
          id: 5,
          index:5,
        },
                {
          bgImg: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-30/car-done.svg"),
          id: 6,
          index:6,
        },
      ],
      optionImgList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-30/cup.svg"),
          index:1,
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-30/muffin.svg"),
          index:2,
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-30/fruit.svg"),
          index:3,
        },
        {
          id: 4,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-30/ball.svg"),
          index:4,
        },
        {
          id: 5,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-30/bear.svg"),
          index:5,
        },
        {
          id: 6,
          image: require("@/assets/img/16-GAMES/G32-clothes-drag-n-drop/lesson-30/car.svg"),
          index:6,
        },
      ],
    };
  },
  components: {
    DragToDressGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











