<template>
  <div class="game-container">
    <FindElementGame :imgList="imgList" :bgImg="bgImg" :lessonNo="30" />
  </div>
</template>

<script>
import FindElementGame from "@/components/Course/GamePage/FindElementGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-30-2/background.svg"),
      imgList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-30-2/mom-found.svg"),
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-30-2/dad-found.svg"),
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-30-2/boy-found.svg"),
        },
        {
          id: 4,
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-30-2/girl-found.svg"),
        },
        {
          id: 5,
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-30-2/cat-1-found.svg"),
        },
        {
          id: 6,
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-30-2/cat-2-found.svg"),
        },
      ],
    };
  },
  components: {
    FindElementGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>