<!--
 * @Author: your name
 * @Date: 2022-03-15 09:05:33
 * @LastEditTime: 2022-03-15 17:41:15
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nihaokidsNew-front\src\views\Course\Level1\Chapter3\Lesson22\11_PracticePageOne_shuzhe.vue
-->
<template>
  <div class="game-container layout-border">
    <FindStroke
      :bgImg="bgImg"
      :gameIndex="gameIndex"
      :strokeImgList="strokeImgList"
      :strockNotFoundImage="strockNotFoundImage"
      :strockFoundImage="strockFoundImage"
    />
  </div>
</template>

<script>
import FindStroke from "@/components/Course/GamePage/FindStrokeGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-30/background-beach.svg"),
      gameIndex:30,
      strokeImgList: [
         {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-30/stroke-castle.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-30/stroke-castle-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-30/stroke-palm.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-30/stroke-palm-found.svg"),
        },
       {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-30/stroke-shore.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-30/stroke-shore-found.svg"),
        },
       
         {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-30/stroke-starfish.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-30/stroke-starfish-found.svg"),
        },
         {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-30/stroke-water.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-30/stroke-water-found.svg"),
        },
      ],
      strockNotFoundImage: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-30/stroke-hengpiewangou.svg"),
      strockFoundImage: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-30/stroke-hengpiewangou-found.svg"),
    };
  },
  components: {
    FindStroke,
  },
};
</script>

<style lang="scss" scoped>
</style>