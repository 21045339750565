<!--
 * @Author: your name
 * @Date: 2022-03-29 09:28:25
 * @LastEditTime: 2022-03-30 14:00:30
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nihaokidsNew-front\src\views\Course\Level1\Chapter5\Lesson30\13_PracticePageTwo_hengzhewan.vue
-->
<template>
  <div class="game-container layout-border">
    <FindStrokeTrain
      :targetStrokeImg="targetStrokeImg"
      :gameIndex="gameIndex"
      :hanziStrokeInfo="hanziStrokeInfo"
      :lightIndex="lightIndex"
      :strokePinyin="strokePinyin"
      @showStroke="handleShowStroke"
      :shadowImg="shadowImg"
      :headerBgImg="headerBgImg"
    />
  </div>
</template>

<script>
import FindStrokeTrain from "@/components/Course/GamePage/FindStrokeTrainGame";
export default {
  data() {
    return {
      headerBgImg: {
        defaultImg:require("@/assets/img/16-GAMES/G16-click-strokes/lesson-30/ship-1.svg"),
        firstImg:require("@/assets/img/16-GAMES/G16-click-strokes/lesson-30/ship-2.svg"),
        secondImg:require("@/assets/img/16-GAMES/G16-click-strokes/lesson-30/ship-3.svg"),
        thirdImg:require("@/assets/img/16-GAMES/G16-click-strokes/lesson-30/ship-4.svg"),
      },
      shadowImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-30/wave.svg"),
      targetStrokeImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-30/30-hengpiewangou-white.svg"),
      strokePinyin: "héngpiěwāngōu",
      gameIndex: 30,
      lightIndex: [1, 2, 3],
      hanziStrokeInfo: [
        {
          pinyin: "duì",
          strokeBgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-30/dui-grey.svg"),
          strokeFinishImgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-30/dui-1.svg"),
              isShow: false,
              index: 1,
            },
          ],
        },
        {
          pinyin: "nǎ",
          strokeBgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-30/na-grey.svg"),
          strokeFinishImgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-30/na-1.svg"),
              isShow: false,
              index: 2,
            },
            
          ],
        },
        {
          pinyin: "duò",
          strokeBgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-30/duo-grey.svg"),
          strokeFinishImgList: [
            {
              bgImg: require("@/assets/img/16-GAMES/G16-click-strokes/lesson-30/duo-1.svg"),
              isShow: false,
              index: 3,
            },
            
          ],
        },
      ],
    };
  },
  components: {
    FindStrokeTrain,
  },
  methods: {
    handleShowStroke(index, indexSon) {
      this.hanziStrokeInfo[index].strokeFinishImgList[indexSon].isShow = true;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>